// http://javascript-minifier.com/
var autocomplete_city_cache = { byCity: {}, byZoneId: {} };

function updateCashback() {
	if ( !$('.j-cart-cashback').length ) {
		return;
	}
	let checked = $('input[name=useMindboxBonuses]').prop('checked');
	let $cashboxHolder = $('.j-cart-cashback');
	let full = $cashboxHolder.data('full');
	let bonus = $cashboxHolder.data('bonus');
	if ( checked ) {
		if ( bonus > 0 ) {
			$cashboxHolder.text(bonus).closest('div').removeClass('d-none');
		} else {
			$cashboxHolder.closest('div').addClass('d-none');
		}
	} else {
		if ( full > 0 ) {
			$cashboxHolder.text(full).closest('div').removeClass('d-none');
		} else {
			$cashboxHolder.closest('div').addClass('d-none');
		}
	}
}

$.fn.redrawMySelect = function() {
	if( 'styler' in $.fn ) {
		$('.js-styled').styler('destroy');
		$('.js-styled').styler();
	}
}
var defaultValidateSetup = {
	description : {
		commonDescription : {
			required : '<div class="error">���� ����������� ��� ����������</div>',
			rus : '<div class="error">����� ������ ���� �� ������� �����</div>',
			name : '<div class="error">�������� ������</div>',
			fullname : '<div class="error">��������� � �������: "��� �������" �������� �������</div>',
			email : '<div class="error">�� ����� ����� e-mail</div>',
			phone_10 : '<div class="error">�� ����� ����� ����� ��������</div>',
			phone_11 : '<div class="error">�� ����� ����� ����� ��������</div>',
			pass : '<div class="error">������ ������ ��������� ������� 5 ��������</div>',
			passConfirm : '<div class="error">���� "������" � "������������� ������" ������ ���������</div>',
			valid : '<div class="success">��</div>',
			autocompleteCity : '<div class="error">�� ������ �����</div>',
			numeric : '<div class="error">�������� ������</div>',
			dateRu : '<div class="error">�������� ������ ���� (��.��.����)</div>',
		}
	},
	eachInvalidField: function( event, statuses, options ) {
		var dataDescription = $(this).data('description') || 'commonDescription';
		var text = '';
		$.each( statuses, function(i, val) {
			if ( val == false ) {
				if( i == 'conditional' ) {
					text = options.description[ dataDescription ][ statuses.conditionalName ] || options.description[ 'commonDescription' ][ statuses.conditionalName ] || '';
				} else {
					text = options.description[ dataDescription ][ i ] || options.description[ 'commonDescription' ][ i ] || '';
				}
			}
		} );
		fieldDescribedby = $(this).data('describedby');
		$(this).addClass('valid-error');
		if( fieldDescribedby != undefined && $('#'+fieldDescribedby).length ) {
			$('#'+fieldDescribedby).html(text);
		} else {
			$(this).closest('.j-value').find('.validation-description').html(text);
		}
	},
	eachValidField : function() {
		fieldDescribedby = $(this).data('describedby');
		$(this).removeClass('valid-error');
		if( fieldDescribedby != undefined && $('#'+fieldDescribedby).length ) {
			$('#'+fieldDescribedby).html('');
		} else {
			$(this).closest('.j-value').find('.validation-description').html('');
		}
	},
	invalid: function() {
		$(this).addClass('form-valid-error');
		$('.sc-form-error').show();
		setTimeout(function() { $('.sc-form-error').hide(); }, 2000);
	},
	valid: function() {
		$(this).removeClass('form-valid-error');
	},
	conditional: {
		rus: function( value, options ) {
			return value.length == 0 || /^[�-��-߸�\s\-,]+$/.test(value);
		},
		name: function( value, options ) {
			return value.length == 0 || /^[�-��-߸�a-zA-Z\s\-,]+$/.test(value);
		},
		fullname: function( value, options ) {
			return value.length == 0 || /^[�-��-߸�a-zA-Z\s\-,]+ [�-��-߸�a-zA-Z\s\-,]+$/.test(value);
		},
		email: function( value, options ) {
			return value.length == 0 || /[-0-9a-z_]+@[-0-9a-z_^.]+\.[a-z]+/i.test(value);
		},
		phone_11: function( value, options ) {
			if( $.inArray( PROSKTR.country.code, ['RU', 'KZ', 'BY', ] ) >= 0 ) {
				value = value.replace(/[^0-9]/g,"");
				return value.length==PROSKTR.country.phoneLength || value.length==0;
			} else {
				var iti = $(this).data('iti');
				return iti.isValidNumber() || value.length==0;
			}
		},
		pass: function( value, options ) {
			return value.length>=5;
		},
		passConfirm: function( value, options ) {
			var passEl = $(this).closest('form').find('input[name=password]');
			return value == passEl.val();
		},
		autocompleteCity: function( value, options ) {
			var holder = $(this).closest('.city-holder');
			var city = $('input[name=city]', holder);
			var zone_id = $('[name=zone_id]', holder);
			return parseInt(zone_id.val()) > 0 && city.val().length > 0;
		},
		numeric: function( value, options ) {
			return !isNaN( value.trim() );
		},
		ajaxEmail: function( value, options, a ) {
			// email_statuses: 
			// 0 - not_checked / checking / error
			// 1 - checked - email_not_exists
			// 2 - checked - email_exists
			// 3 - logined
			// 4 - process
			var status = $(this).data('validEmailStatus');
			var phoneEl = $(this).closest('form').find('input[name=phone]');
			if (phoneEl.length == 0) {
				phoneEl = $(this).closest('form').find('input[name=telephone]');
			}
			if ( phoneEl.length > 0 && phoneEl.val().length > 0 ) {
				status = 3;
				$(this).data('validEmailStatus', status);
				if( !$(this).closest('form').hasClass('on-change-validate-form') ) {
					return true;
				}
			}
			if( status == undefined ) {
				status = 0;
			}
			var lastVal = $(this).data('lastVal');
			if( lastVal != $(this).val() ) {
				$(this).data('lastVal', $(this).val());
				status = 0;
			}
			if( status == 1 || status == 3 || customer.isLogin() ) {
				if( !$(this).closest('form').hasClass('on-change-validate-form') ) {
					return true;
				}
			}
			if( $(this).closest('form').hasClass('on-change-validate-form') && this.val() == this.data('old') ) {
				return true;
			}
			var emailEl = this;
			var passEl = $(this).closest('form').find('input[name=password]');
			var passRow = passEl.closest('.pass-row');
			var pass_btn = $('.pass-btn', passRow);
			if( !pass_btn.data('login-binded-email') ) {
				pass_btn.data('login-binded-email', true);
				pass_btn.on('click', function() {
					customer.login(
						{
							customerInfo: {
								pass: passEl.val(),
								email: emailEl.val(),
							},
							error: function(msg) {
								if ( phoneEl.length == 0 || phoneEl.val().length == 0 ) {
									emailEl.data('validEmailStatus', 0);
									passRow.find('.validation-description').html('<div class="error">' + msg.err_mes + '</div>').addClass('not-empty');
								}
							},
							success: function(msg) {
								emailEl.data('validEmailStatus', 3);
								if( PROSKTR.content == 'shopping_cart' ) {
									if ( msg.count_contents_before != msg.count_contents_after ) {
										window.location.reload();
									} else {
										$('#accountInfo').hide();
										$('#order-tab').show();
										$('#delivery').empty();
										$('#customerAddress').empty();
									}
								}
							}
						}
					);
				});
			}
			customer.checkEmail({
				email: value,
				email_exists: function( msg ) {
					passRow.removeClass('hidden');
					passRow.find('.form-control__placeholder').text('������ � email');
					$(emailEl).data('validEmailStatus', 2);
					var moreText = '';
					if ( emailEl.hasClass('registerMail') ) {
						moreText = ' <a class="js-login greydotted">����� � ���� �������</a>';
					}
					var text = '<div class="error">��� e-mail ��� ���������������' + moreText + '</div>';
					if ( PROSKTR.content == 'shopping_cart' ) {
						text = '<div class="error">� ������������' + ( msg['customers_firstname'].length ? ', ' + msg['customers_firstname'] : '' ) + '.<br>����������, ������� ������ ��� �����������. ' + moreText + '</div>'
					}
					$(emailEl).closest('.j-value').find('.validation-description').html(text);
				},
				email_not_exists: function( msg ) {
					if ( passRow.closest('#create-account-form').length == 0 && !phoneEl.hasClass('valid-error') ) {
						passRow.addClass('hidden');
					}
					$(emailEl).data('validEmailStatus', 1);
				},
				error: function( msg ) {
					if ( passRow.closest('#create-account-form').length == 0 && !phoneEl.hasClass('valid-error') ) {
						passRow.addClass('hidden');
					}
					$(this).closest('.j-value').find('.validation-description').html('<div class="error">' + msg.err_mes + '</div>');
					$(emailEl).data('validEmailStatus', 0);
				}
			});
			return false;
		},
		ajaxPhone: function( value, options ) {
			// email_statuses: 
			// 0 - not_checked / checking / error
			// 1 - checked - email_not_exists
			// 2 - checked - email_exists
			// 3 - logined
			// 4 - process
			var emailEl = $(this).closest('form').find('input[name=email_address]');
			var status = $(this).data('validPhoneStatus');

			if( status == undefined ) {
				status = 0;
			}
			var lastVal = $(this).data('lastVal');
			if( typeof lastVal == 'string' ) {
				lastVal = parseInt(lastVal.replace(/\D+/g,""));
			}
			var parsedVal = parseInt($(this).val().replace(/\D+/g,""));
			if( lastVal != parsedVal ) {
				$(this).data('lastVal', parsedVal);
				status = 0;
			}
			var phoneEl = this;
			if( $(this).closest('form').hasClass('on-change-validate-form') && ( parseInt(this.val().replace(/\D+/g,"")) == this.data('old') || status == 1) ) {
				return true;
			}
			if( status == 1 ) {
				return true;
			}
			if( customer.isLogin() && customer.info.phone == parsedVal ) {
				return true;
			}
			var passEl = $(this).closest('form').find('input[name=password]');
			var passRow = passEl.closest('.pass-row');
			var pass_btn = $('.pass-btn', passRow);
			if( !pass_btn.data('login-binded-phone') ) {
				pass_btn.data('login-binded-phone', true);
				pass_btn.on('click', function() {
					customer.login(
						{
							customerInfo: {
								pass: passEl.val(),
								email: phoneEl.val(),
								options: {'email_or_phone':true},
							},
							error: function(msg) {
								phoneEl.data('validPhoneStatus', 0);
								passRow.find('.validation-description').html('<div class="error">' + msg.err_mes + '</div>').addClass('not-empty');
							},
							success: function(msg) {
								phoneEl.data('validPhoneStatus', 3);
								if( PROSKTR.content == 'shopping_cart' && msg.count_contents_before != msg.count_contents_after ) {
									window.location.reload();
								}
							}
						}
					);
				});
			}
			customer.checkPhone({
				phone: value,
				phone_exists: function( msg ) {
					if( msg.phoneThisLogin == 1 ) {
						phoneEl.data('validPhoneStatus', 1);
						phoneEl.trigger('change');
						return true;
					}
					passRow.removeClass('hidden');
					passRow.find('.form-control__placeholder').text('������ � ��������');
					$(phoneEl).data('validPhoneStatus', 2);
					var moreText = '';
					if ( phoneEl.hasClass('fastOrderPhone') ) {
						moreText = ' <a class="toLoginTab greydotted">����� � ������</a>';
					}
					if ( phoneEl.hasClass('registerPhone') ) {
						moreText = ' <a class="js-login greydotted">����� � ���� �������</a>';
					}
					var text = '<div class="error">��� ������� ��� ���������������' + moreText + '</div>';
					if ( PROSKTR.content == 'shopping_cart' ) {
						text = '<div class="error">� ������������' + ( msg['customers_firstname'].length ? ', ' + msg['customers_firstname'] : '' ) + '.<br>����������, ������� ������ ��� �����������. ' + moreText + '</div>'
					}
					$(phoneEl).closest('.j-value').find('.validation-description').html(text);
				},
				phone_not_exists: function( msg ) {
					if( $(this).closest('form').hasClass('on-change-validate-form') ){
						phoneEl.data('validPhoneStatus', 3);
						return true;
					}
					if( $(phoneEl).closest('form').attr('name') == 'create_account' ) {
						// $(phoneEl).closest('form').submit();
					}
					if ( passRow.closest('#create-account-form').length == 0 && !emailEl.hasClass('valid-error') ) {
						passRow.addClass('hidden');
					}
					$(phoneEl).data('validPhoneStatus', 1);
				},
				error: function( msg ) {
					if ( passRow.closest('#create-account-form').length == 0 && !emailEl.hasClass('valid-error') ) {
						passRow.addClass('hidden');
					}
					$(this).closest('.j-value').find('.validation-description').html('<div class="error">' + msg.err_mes + '</div>');
					$(phoneEl).data('validPhoneStatus', 0);
				}
			});
			return false;
		},
		ajaxPhoneAndPassCode: function( value, options ) {
			var phoneEl = this;
			if ( $(phoneEl).data('proccess') > 0 ) return true;
			if ( value.length > 0 ) {
				$(phoneEl).data('proccess', 1);
			}
			var status = $(this).data('validPhoneStatus');

			if( status == undefined ) {
				status = 0;
			}
			var lastVal = $(this).data('lastVal');
			if( typeof lastVal == 'string' ) {
				lastVal = parseInt(lastVal.replace(/\D+/g,""));
			}
			var parsedVal = parseInt($(this).val().replace(/\D+/g,""));
			if( lastVal != parsedVal ) {
				$(this).data('lastVal', parsedVal);
				status = 0;
			}
			if( $(this).closest('form').hasClass('on-change-validate-form') && ( parseInt(this.val().replace(/\D+/g,"")) == this.data('old') || status == 1) ) {
				$(phoneEl).data('proccess', 0);
				return true;
			}
			if( status == 1 ) {
				$(phoneEl).data('proccess', 0);
				return true;
			}
			if( customer.isLogin() && customer.info.phone == parsedVal ) {
				$(phoneEl).data('proccess', 0);
				return true;
			}
			var passEl = $(this).closest('form').find('input[name=passCode]');
			var passRow = passEl.closest('.pass-row');
			var pass_btn = $('.pass-btn', passRow);
			$(phoneEl).removeClass('exists');

			customer.checkPhoneAndSendPassCode({
				phone: value,
				phone_exists: function( msg ) {
					if( msg.phoneThisLogin == 1 ) {
						$(phoneEl).data('validPhoneStatus', 1);
						$(phoneEl).trigger('change');
						$(phoneEl).data('proccess', 0);
						return true;
					}
					passRow.removeClass('d-none');
					$(phoneEl).data('validPhoneStatus', 2);
					var moreText = '';
					if ( phoneEl.hasClass('fastOrderPhone') ) {
						moreText = ' <a class="toLoginTab dotted">����� � ������</a>';
					}
					if ( phoneEl.hasClass('registerPhone') ) {
						moreText = ' <a class="js-login dotted">����� � ���� �������</a>';
					}
					$(phoneEl).addClass('exists').closest('.input-row').find('.validation-description').html('<div class="exists">' + msg.err_mes + '</div>');
				},
				phone_not_exists: function( msg ) {
					if ( passRow.closest('#create-account-form').length == 0 ) {
						passRow.addClass('d-none');
					}
					$(phoneEl).data('validPhoneStatus', 1);
					$(phoneEl).trigger('change');
					$(phoneEl).data('proccess', 0);
				},
				error: function( msg ) {
					if ( passRow.closest('#create-account-form').length == 0 ) {
						passRow.addClass('d-none');
					}
					$(phoneEl).closest('.input-row').find('.validation-description').html('<div class="error">' + msg.err_mes + '</div>');
					$(phoneEl).data('validPhoneStatus', 0);
					$(phoneEl).data('proccess', 0);
				}
			});
		},
		dateRu: function( value, options ) {
			if( !/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/i.test(value) ) {
				return false;
			} else {
				var arrD = value.split(".");
				arrD[1] -= 1;
				var d = new Date(arrD[2], arrD[1], arrD[0]);
				if ((d.getFullYear() == arrD[2]) && (d.getMonth() == arrD[1]) && (d.getDate() == arrD[0])) {
					return true;
				} else {
					return false;
				}
			}
		},
		'autocomplete-city': function( value, option ) {
			if( $(this).is(':disabled') ) {
				return true;
			}
			if( value.length == 0 || value == 0 ) {
				$(this).closest('.for-autocomplete').parent().selectCity('showError', '�� ����� �����');
				return false;
			} else {
				return true;
			}
		}
	}
};
var default_ajax_form_options = {
	dataType: 'json',
	timeout: 15000,
	beforeSubmit: function(formData, jqForm, options, form) {
		if( jqForm.data('zone_id_check') == 1 && array_search( formData, 'zone_id' ).value == 0 ) {
			return false;
		}
		if( jqForm.data('disabled') == 1 ) {
			return false;
		}
		jqForm.addClass('form-loading');
		formData.push({name: 'ajax', value: 3});
	},
	success: function(responseText, statusText, additParam, form) {
		form.removeClass('form-loading');
		if( responseText.data ) {
			$.each( responseText.data, function( selector, data ) {
				el = $( selector );
				el.html( data );
				if( $('form.validate', el) ) {
					$('form.validate', el).validate();
				}
				$('.datepicker', el).datepicker();
				if( $('.default-validate-form', el).length ) {
					$('.default-validate-form', el).validate();
				}
				$('.default-ajax-form', el).ajaxForm( default_ajax_form_options );
				$('.phone-11-dig', el).phoneMask();
				var checkboxes = $('input:checkbox', el);
				if( checkboxes.length ) {
					checkboxes.checkbox();
				}
				if( selector == '.delivery-list' ) {
					var select_city = $('.country-form input[name=city]').val();
					var uri_name = $('#shops-popup select[name=shop_city] option[city='+select_city+']').val();
					$('#shops-popup select[name=shop_city]').val(uri_name).trigger('change');
				}
				$('.tooltip', el).tooltip_init();
				$('.tabset', el).tabset();
				$('.subtabset').tabset({
					control: '.subtabs .subtab span',
					add_parent_active : 'li',
					tab : '.subtab-item',
					hash : false
				});
				$(document).on('click', '.btn-see-map', function( e, delivery ) {
					e.preventDefault();
					var form = $(this).closest('form');
					$('input[name=filtrDelivery]', form).val( delivery == undefined ? '' : delivery );
					var tmp_options = form.serializeArray();
					var options = {};
					for (var i = 0; i < tmp_options.length; i++) {
						options[ tmp_options[i].name ] = tmp_options[i].value;
					}
					$('#see-map').trigger('click');
					ymaps.ready(function() {
						$('#map-popup').shopsMap( options );
					});
				});
				$(".delivery-text .tab").click(function(){
					$(".delivery-text .tab").removeClass("active").eq($(this).index()).addClass("active");
					$(".delivery-text .tab-item").hide().eq($(this).index()).fadeIn()
				}).eq(0).addClass("active");
				init_sc_autocomplete( el );
				// el.find('.fancybox').fancybox();
				if( $.inArray('alignPopup', responseText.options) >= 0 ) {
					var popup = el.closest('.popup');
					if( popup.length ) {
						popup.css({
							'top': (popup.outerHeight() - $(window).height() >= 20 ? 0 : (($(window).height()-popup.outerHeight())/2) - 10 ),
							// 'left': (($(window).width() - popup.outerWidth())/2) + $(window).scrollLeft()
						});
					}
				}
			});
		}
	}
};
if ( typeof $.validateSetup === 'function' )
{
	$.validateSetup(defaultValidateSetup);
}
$.views.helpers({
	json_decode: function( val ) {
		return $.toJSON(val);
	},
	NumToRu_declination: NumToRu_declination,
});
$.widget("ui.tooltip", $.ui.tooltip, {
	options: {
		content: function () {
			return $(this).prop('title');
		},
		open: function( event, ui ) {
			ui.tooltip.addClass($(this).data('tooltip-class'));
		}
	}
});

Customer.prototype.afterLogin = function( data, msg, callback ) {
	$('#header-account').addClass('mfp-hide');
	$('.mobile-not-login').addClass('mfp-hide');
	$('#header-account-logined').removeClass('mfp-hide');
	$('.mobile-logined').removeClass('mfp-hide');
	$('.js-header-profile__nav a[data-href]').each(function(){
		$(this).attr('href',$(this).attr('data-href'));
	})
};
Customer.prototype.afterLogoff = function( msg, callback ) {
	$('#header-account').removeClass('mfp-hide');
	$('.mobile-not-login').removeClass('mfp-hide');
	$('#header-account-logined').addClass('mfp-hide');
	$('.mobile-logined').addClass('mfp-hide');
};
Customer.prototype.mailCreateSuccess = function( msg ) {
	$('#header-account').addClass('mfp-hide');
	$('.mobile-not-login').addClass('mfp-hide');
	$('#header-account-logined').removeClass('mfp-hide');
	$('.mobile-logined').removeClass('mfp-hide');
	shoppingCart.reloadPopup();
	this.logined = true;
	this.info.email = msg.customers_email_address;
	this.info.customer_id = parseInt(msg.err_mes);
	this.info.asLastOrder = false;
	this.info.name = msg.name;
	this.info.lastname = msg.lastname;
	if( typeof this.onLogin == 'function' ) {
		this.onLogin();
	}
}
Customer.prototype.callUserFunc = function( userData, msg ) {
	switch (msg.result) {
		case "1":
			if ((msg.err_code === 'phone_exists' || msg.err_code === 'email_exists') && typeof userData.error === 'function'){
				userData.error( msg );
			}
			if( typeof userData.success == 'function' ) {
				userData.success( msg );
			}
			break;
		case "0":
			if( typeof userData.error == 'function' ) {
				userData.error( msg );
			}
			$('.js-form-error').html(msg.err_mes);
			setTimeout(function(){
				$('.js-form-error').html('');
			},5000)
			break;
	}
	if( typeof userData[msg.err_code] == 'function' ) {
		userData[msg.err_code](msg);
	}
};
Listing.prototype.settings.sliderElement = '#filtr-form .js-range';
Listing.prototype.settings.setDisabledFilter = true;
// Listing.prototype.settings.divFiltrs = '.catalog-filter__form';
// Listing.prototype.settings.formFiltrs = '.catalog-filter__item';
Listing.prototype.listAjaxBefore = function() {
	$('.catalog-head').addClass('loading');
	$('.mobile-loading').addClass('loading');
};
Listing.prototype.settings.formShowResponse = function(responseText, statusText, additParam, jqForm) {
	jqForm.removeClass('loading');
	$('.catalog-footer .more-button, .prev-one .more-button').removeClass('loading');
	$('.catalog-head').removeClass('loading');
	var productsWrap = $('.catalog-container.dynamic');
	// $('.catalog-products-count').removeClass('loading');
	// $('.mobile-loading').removeClass('loading');
	// $('.more-btn').removeClass('loading');
	if( $('#show_more').val() == 0 ) {
		var productsWrapDynamic = $('.catalog-container.ordered-recently');
		if( productsWrapDynamic.length ) {
			productsWrapDynamic.next().remove();
			productsWrapDynamic.prev().remove();
			productsWrapDynamic.remove();
		}
		productsWrap.empty();
	}

	var productsHtml = $.render[ 'listing_product' ].render( responseText.productsList, { currencies: window.PROSKTR.currencies } );
	// $('.catalog-products-section .catalog-grid .psuedo-item').remove();
	if( $('#show_more').val() == 2 ) {
		productsWrap.prepend(productsHtml);
	} else {
		productsWrap.append(productsHtml);
	}
	// $('.catalog-products-section .catalog-grid').append('<div class="catalog-grid-item psuedo-item"></div>');
	// $('.catalog-products-section .catalog-grid').append('<div class="catalog-grid-item psuedo-item"></div>');
	// $('.catalog-products-section .catalog-grid').append('<div class="catalog-grid-item psuedo-item"></div>');

	window.listing.loading = false;
	if( window.listing.queue ) {
		window.listing.queue = false;
		$('#filtr-form').trigger('change');
	}
	window.listing.setFiltrs( responseText.filtrs );
	$('.catalog-head__result .count-models').html( responseText.productsCount );
	$('.j-m-filters-qty').text(' (' + parseInt( responseText.productsCount ) + ')');
	// $('.filter-btns .num').html( '(' + responseText.pagination.allItems + ')' );
	// $('.reset-btn').css('visibility', $('.filter-holder>.multiselect-enabled.active').length == 0 ? 'hidden' : 'visible');
	$('.filter-trigger').closest('.catalog-filter__item')[ $('#filtr-form .multifilter-item-new.active').length ? 'addClass' : 'removeClass' ]('active');
	if( $('#show_more').val() == 0 ) {
		if( responseText.pagination.pageNumber == 1 ) {
			$('.link-prev-one').parent().hide();
		} else {
			$('.link-prev-one').parent().show();
		}
	}
	if( $('#show_more').val() == 2 ) {
		if( $('.link-prev-one').data('page') == 0 ) {
			$('.link-prev-one').parent().hide();
		}
		$('input[name=page]', jqForm).val( window.listing.lastPage );
	} else {
		$('input[name=page]', jqForm).val( responseText.pagination.pageNumber );
		jqForm.data('pagesCount', responseText.pagination.pageCount);
		$('.pagination').html( responseText.pagination.html );
		$('.more-button')[ responseText.pagination.isLast ? 'addClass' : 'removeClass' ]('mfp-hide');
		$('.listing-progress-text__viewed-count').text( Math.min( responseText.pagination.toItems, responseText.pagination.allItems ) );
		$('.listing-progress-text__all-count').text( responseText.pagination.allItems );
		$('.listing-progress-bar').val( Math.min( responseText.pagination.toItems, responseText.pagination.allItems ) );
		$('.listing-progress-bar').prop( 'max', responseText.pagination.allItems );
	}
	if( window.PROSKTR.abtests.AB_Catalog_FiltrMenuWhenClickToFiltr != undefined && window.PROSKTR.abtests.AB_Catalog_FiltrMenuWhenClickToFiltr.status == 1 ) {
		if( responseText.categoriesTree != undefined ) {
			$('.catalog .side-categories').html( responseText.categoriesTree );
		}
	}
	productsWrap.imagesLoaded( function() {
		$('.catalog-item-4 .img-wrapper').matchHeight();
	} );
	$('.catalog-item-4 .title-link h4').matchHeight();
	$('#show_more').val( 0 );

	var productsArr = [];
	var totalPrice = [];
	for (var i = responseText.productsList.length - 1; i >= 0; i--) {
		productsArr.push( '' + responseText.productsList[i].products_id );
		totalPrice.push( responseText.productsList[i].current_price );
	}
	$('.j-list-checkbox').each(function(){
		var showFlag = $('.list-checkbox__item.multifilter-item-enabled', this).length;
		if ( showFlag ) {
			$(this).find('.j-list-checkbox__header').removeClass('hidden');
		} else {
			$(this).find('.j-list-checkbox__header').addClass('hidden');
		}
	});
	gtag('event', 'page_view', {
		'send_to': PROSKTR.addsId,
		'ecomm_pagetype': 'fsort',
		'ecomm_prodid': productsArr,
		'ecomm_totalvalue': totalPrice
	});

	if ( viewport().width <= 575 ) {
		$('.fullWidthPhotos .catalog-item-4:nth-child(7n) .seventh-image').each(function(){
			$(this).attr('src', $(this).data('src'));
		});
	}
};
Listing.prototype.hideFiltrs = function() {
	var div = $(this.options.divFiltrs, this.options.form);
	var filtrElements = $(this.options.formFiltrs, this.options.form);
	var elHeight = filtrElements.eq(0).outerHeight(true);
	var togglerAdded = false;
	var toggler = $(this.options.divFiltrsToggler, this.options.form);
	if( !div.is(':visible') ){
		$(this.options.divFiltrsToggler, this.options.form).hide();
		div.closest('.one-row').removeClass('one-row');
		return false;
	} else if (div.height() <= elHeight + 10) {
		$(this.options.divFiltrsToggler, this.options.form).hide();
		div.closest('.one-row').removeClass('one-row');
		return false;
	}
	while( div.height() > elHeight + 10 ) {
		if( !togglerAdded ) {
			togglerAdded = true;
			toggler.css('display', 'inline-block');
		}
		toggler.after(filtrElements.last().hide().addClass('toggled'));
		filtrElements = filtrElements.filter(':visible');
	}
	div.closest('.one-row').removeClass('one-row');
};

ShoppingCart.prototype.addProductCallback = function( data ) {
	return function( msg, textStatus, jqXHR ) {
		var cart_info = $('.drop-cart');
		var that = this;
		this.setQuantityNPrice( msg );
		if( msg.dublicate ) {
		} else {
			this.reloadPopup();
			if (typeof data.success == 'function') {
				data.success.call(this, msg);
			}
			addProductEvent( msg );
			$('.j-show-add-product-modal').trigger('click');

			if( window.PROSKTR.content == "product_info" ) {
			} else {
				if( $('#product-preview').hasClass('in') ) {
					$('#product-preview').one('hidden.bs.modal', function() {
						$('#product-added').trigger('click');
					});
					$('#product-preview').modal('hide');
				}
			}
		}
	}
}

ShoppingCart.prototype.reloadPopupCallback = function() {
	return function( msg, textStatus, jqXHR ) {
		var that = this;
		var lastAddedProductData = false;
		$.each(msg.productsList, function( key, value ) {
			if( value.id == that.lastAddedProduct ) {
				value.lastAdded = true;
				lastAddedProductData = value;
			}
		});
		// var cart = $('.header-basket-holder');
		$('.j-cart-content-count').text(msg.count_contents).attr('data-count',msg.count_contents);
		$('.js-basket-count-products:not([data-count-css])').text(msg.count_contents);
		$('.js-basket-count-products[data-count-css]').attr('data-count-css',msg.count_contents);
		$('.j-cart-content-total').text( msg.total_price );
		$('.header-cart__list').mCustomScrollbar('destroy');
		$('.header-cart__list').html($.render[ 'shopping_cart_popup_product' ].render(msg.productsList));
		$('.header-cart__list').mCustomScrollbar({
			axis:"y",
			theme:"my-theme",
			autoExpandScrollbar:true,
			advanced:{autoExpandHorizontalScroll:true}
		});
		if( lastAddedProductData !== false ) {
				var addedWindow = $('.addedProduct');
				addedWindow.slideUp().empty();
				addedWindow.addClass('active').html( $.render[ 'shopping_cart_popup_product_added' ].render([lastAddedProductData]) );

				setTimeout(function(){
					addedWindow.slideDown();
					// if ( viewport().width <= 768 ) {
						addedWindow.next('.addedProductBg').addClass('active');
					// }
				}, 500);
		}
		// if( msg.productsList.length > 4 ) {
		// 	$('.minibasket__button_top', cart).removeClass('d-none');
		// } else {
		// 	$('.minibasket__button_top', cart).addClass('d-none');
		// }
		// if( msg.count_contents > 0 ) {
		// 	$('.header-cart').addClass('basket-opener');
		// } else {
		// 	$('.header-cart').removeClass('basket-opener');
		// }
	}
}

ShoppingCart.prototype.deleteProductCallback = function( data ) {
	return function( msg, textStatus, jqXHR ) {
		if(!msg.products.length && typeof mindbox === "function"){
			mindbox("async", {
				operation: "jeansDean.ClearCart",
				data: {}
			});
		}
		var _this = this;
		shoppingCart.reloadPopup();
		shoppingCart.setQuantityNPrice( msg );

		$('.delete-form [name="products_id[]"][value="' + data.products_id + '"]').closest(".cart-products__item").fadeOut();
		if (typeof data.success == 'function') {
			data.success.call(_this, msg);
		}
		deleteProductEvent(msg);
	}
};

ShoppingCart.prototype.setQuantityNPrice = function( msg ) {
	var cart_info = $('.basket-aside');
	msg = msg || false;
	if ( msg.allProductsTotal_text && $('.js-allproductstotal-text').length ) {
		$('.js-allproductstotal-text').html(msg.allProductsTotal_text);
	}
	// �� �������� data
	if( msg ){
		var basketProductsPopupHtml = $.render[ 'basket_popup_products_list_v2' ].render( msg, { currencies: window.PROSKTR.currencies } );

		$('.js-basket-products-popup').html(basketProductsPopupHtml);

		sendStatistics(msg.products);
		$('.j-cart-content-total').text( msg.total_price );
		$('.j-cart-content-real-total').text( msg.real_total + ' ' + window.PROSKTR.currencies.rightMin );
		$('.j-cart-full-total').text( msg.total_price + ' ' + window.PROSKTR.currencies.rightMin );
		$('.j-product-counts-full').text( msg.count_contents + ' ' + msg.countInCartGoods );
		$('.j-cart-delivery-total').text( '0 ' + window.PROSKTR.currencies.rightMin );
		var discount = parseInt( msg.real_total ) - msg.total_price;
		$('.j-cart-discount').text( ( discount > 0 ? discount : 0 ) + ' ' + window.PROSKTR.currencies.rightMin );

		$('.j-cart-content-count').text(msg.count_contents).attr('data-count',msg.count_contents);
		$('.js-basket-count-products:not([data-count-css])').text(msg.count_contents);
		$('.js-basket-count-products[data-count-css]').attr('data-count-css',msg.count_contents);
		if ( msg.count_contents > 0 ) {
			$('#notEmptyCart').removeClass('mfp-hide');
			$('.dropDown-body.not-empty').removeClass('mfp-hide');
			$('#emptyCart').addClass('mfp-hide');
			$('.dropDown-body.empty').addClass('mfp-hide');
		} else {
			$('#emptyCart').removeClass('mfp-hide');
			$('.dropDown-body.empty').removeClass('mfp-hide');
			$('#notEmptyCart').addClass('mfp-hide');
			$('.dropDown-body.not-empty').addClass('mfp-hide');
			$('.fixed-basket-totals').empty();
		}

		cookieObj.setCookie('customerorderCnt',  msg.count_contents );
		$('#form-get-delivery').submit();

		$('.j-cart-cashback').data('full', msg.mindboxFullCashback);
		$('.j-cart-cashback').data('bonus', msg.mindboxBonusCashback);
		updateCashback();

		if ( window.PROSKTR.content != 'shopping_cart' ) {
			return;
		}
		var productsHtml = $.render[ 'show_product_tpl' ].render( msg.products, { currencies: window.PROSKTR.currencies } );
		$('#checkoutProductsList').html('');
		$('#checkoutProductsList').append(productsHtml);


		if ( msg.otherWhGroups ) {
			$('.j-firstorder').removeClass('d-none');
			let mainSummary = (msg.count_contents * 1) + ' ' + NumToRu_declination(msg.count_contents, '�����', '������', '�������') + ' �� ����� <span>' + (msg.total * 1) + ' ' + window.PROSKTR.currencies.rightMin + '</span>';
			$('.j-main-summary').html(mainSummary).removeClass('d-none');
			let $otherHolder = $('.j-otherGroups');
			$otherHolder.html('');

			let i = 2;
			for ( group in msg.otherWhGroups ) {
				let headDiv = document.createElement('div');
				headDiv.className = 'ordergroup-head';
				headDiv.innerHTML = i + ' ����� - ������������ ��������';
				i++;
				$otherHolder.append(headDiv);

				let groupProductsHtml = $.render['show_product_tpl'].render(
					msg.otherWhGroups[group],
					{customer: window.PROSKTR.customer, currencies: window.PROSKTR.currencies}
				);
				let entriesDiv = document.createElement('div');
				entriesDiv.className = 'cart-products';
				entriesDiv.innerHTML = groupProductsHtml;
				$otherHolder.append(entriesDiv);

				let groupCount = 0;
				let groupPrice = 0;
				for (let index = 0; index < msg.otherWhGroups[group].length; index++) {
					const element = msg.otherWhGroups[group][index];
					groupCount += element.quantity * 1;
					groupPrice += element.quantity * element.final_price;
				}

				let totalsDiv = document.createElement('div');
				totalsDiv.className = 'summary';
				let totalsInner = (groupCount * 1) + ' ' + NumToRu_declination(groupCount, '�����', '������', '�������') + ' �� ����� <span>' + (groupPrice * 1) + ' ' + window.PROSKTR.currencies.rightMin + '</span>';
				totalsDiv.innerHTML = totalsInner;
				$otherHolder.append(totalsDiv);
			};
		} else {
			$('.j-firstorder').addClass('d-none');
			$('.j-main-summary').addClass('d-none');
		}

		$('.cart-products__item .del').click(function( e ) {
			e.preventDefault();
			data = {};
			data.products_id = $(this).closest('form').find('[name="products_id[]"]').val();
			data.fromPage = window.PROSKTR.content;
			shoppingCart.deleteProduct( data );
		});
	}
	function sendStatistics(products){
		if(Array.isArray(products) && typeof mindbox === "function"){
			var productList = [];

			for(var i = 0; i<products.length; i++){
				var currentProduct = products[i];

				productList.push({
					product: {
						ids:{
							jeansDean: currentProduct.mindbox_productid
						}
					},
					count: currentProduct.quantity,
					pricePerItem: currentProduct.final_price,
				});
			}
			mindbox("async", {
				operation: "jeansDean.SetCart",
				data: {
					productList: productList,
				},
				onSuccess: function() { },
				onError: function(error) { }
			});
		}
	}
}

$.widget("map.shopsMap", $.map.shopsMap, {
	options: {
		iconsPoint: {
			src: '/templates/iridato/img/icons/marker2.png?1',
			size: [33, 53],
			offset: [-16, -53],
		},
		iconCluster: [
			{
				src: '/templates/iridato/img/krug_ym.png',
				size: [40, 40],
				// ������, ����� ����� �������� �������� � ������� ��������.
				offset: [-20, -20]
			},
			{
				src: '/templates/iridato/img/krug_ym.png',
				size: [50, 50],
				offset: [-25, -25]
			}
		],
		mapTabControl: '#map-control',
		mapLoading: '#mapLoading',
	}
});
WishList.prototype.setTotal = function( count ) {
	if( typeof this.options.setTotal == 'function' ) {
		return this.options.setTotal( count );
	}
	$('.j-wishlist-content-count').text( count ).attr('data-count',count);
};
WishList.prototype.remove = function( productsId, callback ) {
	var sendData = { products_id: productsId };
	if( window.PROSKTR.ajax_csrf_token !== undefined ) {
		sendData.csrf_token = window.PROSKTR.ajax_csrf_token;
	}
	$.ajax({
		url: '/ajax.php?type=wishlist&action=remove',
		type: 'POST',
		data: sendData,
		dataType: 'json',
		context: this,
		success: function(msg) {
			if(typeof mindbox === "function") {
				if (!msg.products.length) {
					mindbox("async", {
						operation: "jeansDean.ClearWishList",
						data: {}
					});
				}else{
					var productList = [];

					for (var i = 0;i<msg.products.length;i++){
						var product = msg.products[i];

						if(product.warehouse_artikul2)
						{
							productList.push({
								productGroup: {
									ids: {
										'jeansDean': product.warehouse_artikul2
									}
								},
								count: 1,
								pricePerItem: product.products_price,
							});
						}
					}
					mindbox("async", {
						operation: 'jeansDean.SetWishList',
						data: {
							productList: productList
						},
						onSuccess: function () {
						},
						onError: function (error) {
						}
					});
				}
			}
			this.setTotal( msg.count );
			this.list = msg.list;
			if( this.useLocalStorage ) {
				localStorage.setItem('wishList', JSON.stringify( this.list ));
			}
			if( typeof this.options.afterRemove == 'function' ) {
				return this.options.afterRemove( productsId, msg );
			}
			if( typeof callback == 'function' ) {
				callback();
			}
			if( Array.isArray(productsId) ) {
				for (var i = productsId.length - 1; i >= 0; i--) {
					removeWishlistEvent( productsId[i], msg );
				}
			} else {
				removeWishlistEvent( productsId, msg );
			}
		}
	});
};
WishList.prototype.add = function( productsId, callback ) {
	var sendData = { products_id: productsId };
	if( window.PROSKTR.ajax_csrf_token !== undefined ) {
		sendData.csrf_token = window.PROSKTR.ajax_csrf_token;
	}
	$.ajax({
		url: '/ajax.php?type=wishlist&action=add',
		type: 'POST',
		data: sendData,
		dataType: 'json',
		context: this,
		success: function(msg) {
			this.setTotal( msg.count );
			this.list.push( productsId );
			if( this.useLocalStorage ) {
				localStorage.setItem('wishList', JSON.stringify( this.list ));
			}
			if( typeof callback == 'function' ) {
				callback( msg );
			}
			if( typeof this.options.afterAdd == 'function' ) {
				return this.options.afterAdd( productsId, msg );
			}
			if( this.useLocalStorage ) {
				if( msg.count != this.list.length ) {
					this.loadList();
				}
			}
			var productList = [];

			for (var i = 0;i<msg.addedProducts.length;i++){
				var product = msg.addedProducts[i];

				if(product.warehouse_artikul2)
				{
					productList.push({
						productGroup: {
							ids: {
								'jeansDean': product.warehouse_artikul2
							}
						},
						count: 1,
						pricePerItem: product.products_price,
					});
				}
			}
			if(typeof mindbox === "function") {
				mindbox("async", {
					operation: 'jeansDean.SetWishList',
					data: {
						productList: productList
					},
					onSuccess: function () {
					},
					onError: function (error) {
					}
				});
			}
			try {
				ga('send', 'event', 'AB_Wishlist', 'Show_wishlist', 'Show_wishlist_Click', {'nonInteraction': 1});
				window._fbq = window._fbq || [];
				window._fbq.push(['track', 'AddToWishlist', {}]);
			} catch(e) {}
			addWishlistEvent( productsId, msg );
		}
	});
};

InfoShops.prototype.settings.iconImageHref = "/templates/iridato/img/icons/marker2.png?1";
InfoShops.prototype.settings.iconImageHref2 = "/templates/iridato/img/icons/marker2.png?1";
InfoShops.prototype.settings.iconImageSize = [33, 53];
InfoShops.prototype.settings.iconImageOffset = [-16, -53];

// InfoShops.prototype.settings.iconImageHref = "/templates/antanew/images/marker.png";
// InfoShops.prototype.settings.iconImageHref2 = "/templates/antanew/images/marker.png";
// InfoShops.prototype.settings.iconImageSize = [26, 35];
// InfoShops.prototype.settings.iconImageOffset = [-10, -20];

$.fn.phoneMask = function() {
	$(this).each(function() {
		var iti = $(this).data('iti');
		if( $.inArray( PROSKTR.country.code, ['RU', 'KZ', 'BY', ] ) >= 0 ) {
			if( iti != undefined ) {
				iti.destroy();
				$(this).removeData('iti');
			}
			$(this).mask(PROSKTR.country.phoneCode + PROSKTR.country.phoneMask);
			$(this).attr('placeholder', window.PROSKTR.country.phoneExample);
		} else {
			if( iti != undefined ) {
				iti.setCountry( PROSKTR.country.code );
			} else {
				$(this).unmask();
				iti = window.intlTelInput(this, {
					utilsScript: "/includes/javascript/intlTelInput/utils.js?1537717752654",
					initialCountry: PROSKTR.country.code,
					autoHideDialCode: false,
					excludeCountries: ['ru', 'by', 'kz'],
					separateDialCode: true,
					allowDropdown: false,
					autoPlaceholder: 'aggressive'
				});
			}
			$(this).data('iti', iti);
		}
	});
}
